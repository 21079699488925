import { Container, Header, Select } from '@amzn/awsui-components-react/polaris';
import { SelectProps } from '@amzn/awsui-components-react/polaris/select';
import { useTranslation } from 'react-i18next';
import { IconType } from 'react-icons/lib';
import { Fragment, PropsWithChildren, ReactNode, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { SupportedDatasetType } from 'src/utils/planning/planetModel';
import { DatasetOption } from 'src/pages/commons/plan-views/DatasetTabsViewer';
import { SelectChangeEvent } from 'src/common/EventType';
import {
  useTriggerTopsDownVolumeDatasetImport,
  useTriggerTopsDownBaselineCpuDatasetImport,
} from 'src/pages/commons/compute-helpers/useTriggerPlanCompute';
import { useBatchMetadata } from 'src/api/query/useBatchMetadata';
import { useBatchMetadataMutation } from 'src/api/mutation/useBatchMetadataMutation';
import { usePortfolioIds, useScenarios } from 'src/api/query/useBatchDimensionValues';

interface ActionButtonProps {
  icon: IconType;
  text: string;
  onClick: () => void;
  disabled?: boolean;
  hide?: boolean;
}

export type DataGridActionButtonGroups = ActionButtonProps[][];

interface DatasetSelectorProps {
  currentDataset: SupportedDatasetType;
  onDatasetSelected: (e: SelectChangeEvent) => void;
  datasetOptions: DatasetOption[];
}

interface ButtonGroupsProps {
  actionButtonGroups: DataGridActionButtonGroups;
}

interface HeaderProps {
  headerText: ReactNode;
  description: ReactNode;
}

type HeaderActionsProps = DatasetSelectorProps & ButtonGroupsProps;

type DataGridContainerProps = HeaderActionsProps & HeaderProps & PropsWithChildren;

interface AdditionalSelectorProps {
  options: NonNullable<SelectProps['options']>;
  selectedOption: NonNullable<SelectProps['selectedOption']>;
  onChange: NonNullable<SelectProps['onChange']>;
  placeholder: NonNullable<SelectProps['placeholder']>;
}

const AdditionalSelector = (props: AdditionalSelectorProps) => (
  <div style={{ width: 180 }}>
    <Select
      options={props.options}
      selectedOption={props.selectedOption}
      onChange={props.onChange}
      placeholder={props.placeholder}
      filteringPlaceholder={props.placeholder}
      filteringType="auto"
    />
  </div>
);

const TopsDownVolumeSelector = () => {
  const { t } = useTranslation();

  const { batchId } = useParams();

  const { data: batchMetadata } = useBatchMetadata({ batchId });

  const { data: portfolioIds } = usePortfolioIds({ planType: batchMetadata?.costType ?? '' });

  const { triggerTopsDownVolumeDatasetImport } = useTriggerTopsDownVolumeDatasetImport();

  const { mutate: updateBatchMetadata } = useBatchMetadataMutation({
    meta: {
      inProgressNotificationText: t('api_in_progress_update_plan'),
      errorNotificationText: t('api_error_update_plan'),
      successNotificationText: t('api_success_update_plan'),
    },
    onSuccess: () => {
      if (batchMetadata?.batchId) {
        triggerTopsDownVolumeDatasetImport({ batchId: batchMetadata.batchId });
      }
    },
  });

  const options = useMemo(
    () => portfolioIds?.map((o) => ({ label: o, value: o })) || [],
    [portfolioIds],
  );

  const selectedOption = options.find(
    (item) => item.value === batchMetadata?.moduleSpecificMetadata?.portfolioId?.[0],
  )!;

  const onChange = (e: SelectChangeEvent) => {
    updateBatchMetadata({
      ...batchMetadata,
      moduleSpecificMetadata: {
        ...batchMetadata?.moduleSpecificMetadata,
        portfolioId: [e.detail.selectedOption.value as string],
      },
    });
  };

  return (
    <AdditionalSelector
      options={options}
      selectedOption={selectedOption}
      onChange={onChange}
      placeholder={t('portfolio_selector_placeholder')}
    />
  );
};

const TopsDownBaselineCpuSelector = () => {
  const { t } = useTranslation();

  const { batchId } = useParams();

  const { data: batchMetadata } = useBatchMetadata({ batchId });

  const { data: scenarios } = useScenarios({ planType: batchMetadata?.costType ?? '' });

  const { triggerTopsDownBaselineCpuDatasetImport } = useTriggerTopsDownBaselineCpuDatasetImport();

  const { mutate: updateBatchMetadata } = useBatchMetadataMutation({
    meta: {
      inProgressNotificationText: t('api_in_progress_update_plan'),
      errorNotificationText: t('api_error_update_plan'),
      successNotificationText: t('api_success_update_plan'),
    },
    onSuccess: () => {
      if (batchMetadata?.batchId) {
        triggerTopsDownBaselineCpuDatasetImport({ batchId: batchMetadata.batchId });
      }
    },
  });

  const options = useMemo(() => scenarios?.map((o) => ({ label: o, value: o })) || [], [scenarios]);

  const selectedOption = options.find(
    (item) => item.value === batchMetadata?.moduleSpecificMetadata?.scenario?.[0],
  )!;

  const onChange = (e: SelectChangeEvent) => {
    updateBatchMetadata({
      ...batchMetadata,
      moduleSpecificMetadata: {
        ...batchMetadata?.moduleSpecificMetadata,
        scenario: [e.detail.selectedOption.value as string],
      },
    });
  };

  return (
    <AdditionalSelector
      options={options}
      selectedOption={selectedOption}
      onChange={onChange}
      placeholder={t('scenario_selector_placeholder')}
    />
  );
};

const DatasetSelector = ({
  currentDataset,
  onDatasetSelected,
  datasetOptions,
}: DatasetSelectorProps) => {
  const { t } = useTranslation();

  const selectedOption = datasetOptions.find((item) => item.value === currentDataset)!;

  return (
    <div style={{ width: 300 }}>
      <Select
        options={datasetOptions}
        selectedOption={selectedOption}
        onChange={onDatasetSelected}
        placeholder={t('select_generic_placeholder')}
      />
    </div>
  );
};

const ActionButton = ({ icon, text, onClick, disabled }: ActionButtonProps) => {
  const IconComponent = icon;
  const disabledStyle = disabled ? { color: 'grey', cursor: 'not-allowed' } : {};

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div
      id={`button_${text}`}
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        cursor: 'pointer',
        fontSize: '2.4rem',
        lineHeight: '12px',
        ...disabledStyle,
      }}
      onClick={!disabled ? onClick : undefined}
    >
      <IconComponent />
      <span style={{ fontSize: '9px', fontWeight: 'normal' }}>{text}</span>
    </div>
  );
};

const ActionButtonDivider = () => (
  <div style={{ height: '36px', width: '1px', backgroundColor: 'lightGrey' }} />
);

const HeaderActions = ({
  currentDataset,
  onDatasetSelected,
  datasetOptions,
  actionButtonGroups,
}: HeaderActionsProps) => (
  <div style={{ display: 'flex', gap: '30px' }}>
    {/* Selectors */}
    <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
      {/* Dataset Selector */}
      {datasetOptions.length > 1 ? (
        <DatasetSelector
          currentDataset={currentDataset}
          onDatasetSelected={onDatasetSelected}
          datasetOptions={datasetOptions}
        />
      ) : null}

      {/* Tops Down Forecasting - Volume Portfolio Selector */}
      {currentDataset === SupportedDatasetType.TOPS_DOWN_FORECAST_VOLUME ? (
        <TopsDownVolumeSelector />
      ) : null}

      {/* Tops Down Forecasting - CPU Scenario Selector */}
      {currentDataset === SupportedDatasetType.TOPS_DOWN_FORECAST_Q2G_CPU ? (
        <TopsDownBaselineCpuSelector />
      ) : null}
    </div>

    {/* Button Groups */}
    <div style={{ display: 'flex', alignItems: 'center', gap: '15px' }}>
      {actionButtonGroups.map((group, index) => {
        const showingButtons = group.filter((o) => !o.hide);
        const isShowDivider = index < actionButtonGroups.length - 1 && showingButtons.length;

        return (
          <Fragment key={index}>
            {/* Buttons */}
            {showingButtons.map((button) => (
              <ActionButton
                icon={button.icon}
                text={button.text}
                onClick={button.onClick}
                disabled={button.disabled}
                key={button.text}
              />
            ))}
            {/* Divider */}
            {isShowDivider ? <ActionButtonDivider /> : null}
          </Fragment>
        );
      })}
    </div>
  </div>
);

const DatasetGridContainer = ({
  currentDataset,
  onDatasetSelected,
  datasetOptions,
  actionButtonGroups,
  headerText,
  description,
  children,
}: DataGridContainerProps) => (
  <Container
    header={
      <Header
        actions={
          <HeaderActions
            currentDataset={currentDataset}
            onDatasetSelected={onDatasetSelected}
            datasetOptions={datasetOptions}
            actionButtonGroups={actionButtonGroups}
          />
        }
        description={description}
      >
        {headerText}
      </Header>
    }
  >
    {children}
  </Container>
);

export default DatasetGridContainer;
