import {
  GetBatchResponse,
  UpdateBatchRequest,
  UpdateBatchResponse,
} from '@amzn/fox-den-cost-planning-lambda';
import { find, get } from 'lodash';
import { jsonStringifyNullIfEmpty, safeJsonParseObjectOnly } from 'src/utils/parse';
import { SupportedDatasetType, PeriodTypeId } from 'src/utils/planning/planetModel';

interface IPlanLogicConfig {
  batchName?: string;
  batchVersion?: string;
  batchId?: string;
  sourceLogic?: string;
  sourceType?: string;
  percentageAdjustment?: string;
}

interface IConditionsConfig {
  accountList?: string[];
  countryList?: string[];
  locationTypeList?: string[];
  locationList?: string[];
  metricGroupList: string[];
  metricsNameList?: string[];
  sourcePeriodList?: string[];
  destinationPeriodList?: string[];
}

interface IBaselineConfigItem {
  planLogicConfig?: IPlanLogicConfig;
  conditionsConfig?: IConditionsConfig;
  modifiedBy?: string;
  modifiedAt?: string;
  executingOrder?: number;
}

interface IBaselineConfig {
  lastConfiguredTime?: string;
  baselineConfigItemList?: IBaselineConfigItem[];
}

export interface IDetailedComputeStep {
  stepName?: string;
  completeDatetime?: string;
  status?: string;
  detail?: string;
}

interface IComputeStatus {
  status?: string;
  triggerDatetime?: string;
  completeDatetime?: string;
  triggeredBy?: string;
  detailedSteps?: IDetailedComputeStep[];
  trigger?: string;
}

interface IParsedBatchMetadataFields {
  startDay?: string;
  baselineConfig?: IBaselineConfig;
  metricsConfig?: Record<any, any>;
  viewerConfig?: Record<any, any>;
  datasetLocations?: any[];
  datasetDimensionValues?: Record<any, any>;
  datasetSchemaConfig?: { inputDataSchema: ITableDataSchema[]; [key: string]: any };
  computeStatus?: IComputeStatus;
  referenceTables?: Record<any, any>;
  exportConfig?: Record<any, any>;
}

export interface IBatchMetadataParsed
  extends Omit<GetBatchResponse, keyof IParsedBatchMetadataFields>,
    IParsedBatchMetadataFields {}

export interface IUpdateBatchMetadataParsed
  extends Omit<UpdateBatchResponse, keyof IParsedBatchMetadataFields>,
    IParsedBatchMetadataFields {}

export const parseBatchMetadataFields = (
  batchMetadata: GetBatchResponse,
): IBatchMetadataParsed => ({
  ...batchMetadata,
  baselineConfig: safeJsonParseObjectOnly(batchMetadata.baselineConfig),
  metricsConfig: safeJsonParseObjectOnly(batchMetadata.metricsConfig),
  viewerConfig: safeJsonParseObjectOnly(batchMetadata.viewerConfig),
  datasetLocations: safeJsonParseObjectOnly(batchMetadata.datasetLocations),
  datasetDimensionValues: safeJsonParseObjectOnly(batchMetadata.datasetDimensionValues),
  datasetSchemaConfig: safeJsonParseObjectOnly(batchMetadata.datasetSchemaConfig),
  computeStatus: safeJsonParseObjectOnly(batchMetadata.computeStatus),
  referenceTables: safeJsonParseObjectOnly(batchMetadata.referenceTables),
  exportConfig: safeJsonParseObjectOnly(batchMetadata.exportConfig),
});

export const stringifyBatchMetadataFields = (batchMetadata: any): UpdateBatchRequest => ({
  ...batchMetadata,
  viewerConfig: jsonStringifyNullIfEmpty(batchMetadata.viewerConfig),
  baselineConfig: jsonStringifyNullIfEmpty(batchMetadata.baselineConfig),
  metricsConfig: jsonStringifyNullIfEmpty(batchMetadata.metricsConfig),
  exportConfig: jsonStringifyNullIfEmpty(batchMetadata.exportConfig),
});

export interface ITableDataSchemaColumnItem {
  dimensionName: string;
  dataType: string;
  required?: boolean;
}

export type ITableDataSchemaColumnList = ITableDataSchemaColumnItem[];

export interface ITableDataSchema {
  schemaName: string;
  columnList: ITableDataSchemaColumnList;
}

export const getSchemaForDataset = (
  datasetName: SupportedDatasetType,
  batchMetadata: IBatchMetadataParsed | undefined,
) => {
  const schemas = batchMetadata?.datasetSchemaConfig?.inputDataSchema;
  return schemas?.find((o) => o.schemaName === datasetName && o.columnList)?.columnList;
};

export const getLastUpdatedOnOfDataset = (
  batchMetadata: IBatchMetadataParsed,
  datasetName: string,
): string => get(find(batchMetadata.datasetLocations, { datasetName }), 'lastUpdatedOn', '');

export const getPlanDuration = (
  startDate: string,
  endDate: string,
  periodType: PeriodTypeId,
): number => {
  const d1 = new Date(startDate);
  const d2 = new Date(endDate);
  let duration = 0;

  if (d1 && d2) {
    switch (periodType) {
      /**
       * Here we use months duration for quarterly plan
       * - However it doesn't really matter as we did not use this value at BE
       * - Also plan duration shouldn't be passed from FE as it is a bad practice, instead, it should be calculated at BE
       */
      case PeriodTypeId.QUARTERLY:
      case PeriodTypeId.MONTHLY:
        duration = (d2.getFullYear() - d1.getFullYear()) * 12 + (d2.getMonth() - d1.getMonth());
        return (duration <= 0 ? 0 : duration) + 1;
      case PeriodTypeId.WEEKLY:
        duration = (d2.getTime() - d1.getTime()) / (1000 * 60 * 60 * 24 * 7);
        return Math.abs(Math.round(duration)) + 1;
    }
  }

  return 0;
};
