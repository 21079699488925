/**
 * Configuration required for Amplify.
 * Ref: https://docs.amplify.aws/lib/client-configuration/configuring-amplify-categories/q/platform/js/
 */

export const envConfig = {
  Auth: {
    // (required) only for Federated Authentication - Amazon Cognito Identity Pool ID
    identityPoolId: 'us-west-2:8f82952e-a946-456b-8b0f-2e0d29d7f3d8',

    // (required)- Amazon Cognito Region
    region: 'us-west-2',

    // (optional) - Amazon Cognito User Pool ID
    userPoolId: 'us-west-2_oN4wEMYYl',

    // (optional) - Amazon Cognito Web Client ID (26-char alphanumeric string, App client secret needs to be disabled)
    userPoolWebClientId: '2s8jnuacit0bkgmgu7ullsukpe',

    // (optional) - Enforce user authentication prior to accessing AWS resources or not
    mandatorySignIn: true,

    // (optional) - Hosted UI configuration
    oauth: {
      domain: 'planet-access-control-prod' + '.auth.us-west-2.amazoncognito.com',
      redirectSignIn: 'https://' + window.location.host + '/',
      redirectSignOut: 'https://' + window.location.host + '/',
      scope: ['openid'],
      responseType: 'code',
    },
  },
  API: {
    endpoints: [
      {
        name: 'FoxCostPlanningLambdaEndpoint',
        endpoint: 'https://xmarlya5s4.execute-api.us-west-2.amazonaws.com/prod',
        region: 'us-west-2',
      },
    ],
  },
  Storage: {
    AWSS3: {
      writeOnlyBucket: 'fox-cost-planning-write-only-prod',
      bucket: 'fox-cost-planning-prod',
      region: 'us-west-2',
    },
  },
};
