import { BaseExportParams, ProcessHeaderForExportParams } from 'ag-grid-community';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AiOutlineFullscreen } from 'react-icons/ai';
import { BiExport, BiRefresh } from 'react-icons/bi';
import { useParams } from 'react-router';
import { AgGridReact } from 'ag-grid-react';
import { useBatchMetadata } from 'src/api/query/useBatchMetadata';
import { usePlanDataset } from 'src/api/query/usePlanDataset';
import { AgGridBaseWrapper } from 'src/common/ag-grid/AgGridBaseWrapper';
import { AG_OUTPUT_PAGINATION_PAGE_SIZE } from 'src/pages/plan-manager-page/plan-output-tab/planOutputConfig';
import {
  ITableDataSchemaColumnItem,
  getSchemaForDataset,
} from 'src/utils/planning/batchMetadataModel';
import { getColumnDefs } from 'src/utils/planning/getColumnDefs';
import { SupportedDatasetType, UTR_PLAN_TYPES } from 'src/utils/planning/planetModel';
import { useAgGridResizeColumns } from 'src/common/ag-grid/useAgGridResizeColumns';
import { SelectChangeEvent } from 'src/common/EventType';
import { GridComponentProps } from 'src/pages/commons/plan-views/DatasetTabsViewer';
import DatasetGridContainer, {
  DataGridActionButtonGroups,
} from 'src/pages/commons/plan-views/DatasetGridContainer';
import { useTriggerExtractOutput } from 'src/pages/commons/compute-helpers/useTriggerPlanCompute';
import { useStaticFilterList } from 'src/api/query/useBatchDimensionValues';
import { IFilterList, useFilterComponent } from 'src/pages/commons/plan-filters/useFilterComponent';
import { generateStaticSiteList } from 'src/pages/commons/plan-filters/StaticFilterList';
import { useAgGridCalculatedHeight } from 'src/common/ag-grid/useAgGridCalculatedHeight';

const LARGE_OUTPUT_PLAN_TYPES: ReadonlySet<string> = UTR_PLAN_TYPES;

const PlanOutputDataGrid = ({
  currentDataset,
  setCurrentDataset,
  datasetOptions,
  headerText,
  description,
}: GridComponentProps) => {
  const gridRef = useRef<AgGridReact>(null);

  const containerRef = useRef<HTMLDivElement>(null);

  const [isFullScreen, setIsFullScreen] = useState(false);

  const { t } = useTranslation();

  const { batchId } = useParams();

  const { data: batchMetadata } = useBatchMetadata({ batchId });

  const planRequiresSiteFilter = useMemo(
    () => LARGE_OUTPUT_PLAN_TYPES.has(batchMetadata?.costType ?? ''),
    [batchMetadata?.costType],
  );

  const { data: sitesFilterList, isError: noSitesFilterList } = useStaticFilterList(
    {
      planType: batchMetadata?.costType ?? '',
      subGroup: batchMetadata?.subGroup ?? '',
      region: batchMetadata?.region ?? '',
    },
    { disabled: !batchMetadata || !planRequiresSiteFilter },
  );

  const filterConfig = useMemo(() => {
    const filterInfo: IFilterList = [];
    if (planRequiresSiteFilter) {
      filterInfo.push({
        name: 'site',
        label: 'site_name_filter_label',
        options: noSitesFilterList ? generateStaticSiteList() : sitesFilterList ?? [],
      });
    }

    return filterInfo;
  }, [noSitesFilterList, planRequiresSiteFilter, sitesFilterList]);

  const { filtersValid, filterMap, FilterComponent } = useFilterComponent(filterConfig);

  const setGridAsLoading = () => {
    gridRef?.current?.api?.showLoadingOverlay?.();
  };

  useEffect(() => {
    if (filtersValid && filterMap) {
      setGridAsLoading();
    }
  }, [filterMap, filtersValid]);

  const schema = useMemo(
    () => getSchemaForDataset(currentDataset, batchMetadata),
    [batchMetadata, currentDataset],
  );

  const columnDefs = useMemo(() => getColumnDefs(schema), [schema]);

  const validHeaders = schema?.map((item: ITableDataSchemaColumnItem) => item.dimensionName);

  const {
    data: rawData,
    refetch: refetchData,
    error: fetchDataError,
  } = usePlanDataset(
    {
      datasetName: currentDataset ?? '',
      batchId: batchMetadata?.batchId ?? '',
      filterList: filterMap,
    },
    validHeaders,
    {
      disabled: !batchMetadata || !currentDataset || !filtersValid,
      cacheTime: 0,
      staleTime: Infinity,
    },
  );

  const { triggerExtractOutput, isExtractOutputSupported } = useTriggerExtractOutput(
    batchMetadata?.costType,
  );

  const handleClickExport = () => {
    gridRef.current?.api.exportDataAsExcel();
  };

  const handleClickRefresh = useCallback(() => {
    if (!batchMetadata?.batchId) return;
    if (!isExtractOutputSupported) refetchData();
    else triggerExtractOutput({ batchId: batchMetadata.batchId });
  }, [batchMetadata?.batchId, isExtractOutputSupported, refetchData, triggerExtractOutput]);

  const actionButtonGroups: DataGridActionButtonGroups = [
    [
      { icon: BiExport, text: t('export'), onClick: handleClickExport },
      { icon: BiRefresh, text: t('refresh'), onClick: handleClickRefresh },
    ],
    [{ icon: AiOutlineFullscreen, text: t('expand'), onClick: () => setIsFullScreen(true) }],
  ];

  const handleDatasetSelected = (e: SelectChangeEvent) => {
    const newDataset = e.detail.selectedOption.value as SupportedDatasetType;
    if (newDataset !== currentDataset) setCurrentDataset(newDataset);
  };

  const defaultColDef = {
    sortable: true,
    resizable: true,
    filter: true,
  };

  const { resizeColumns } = useAgGridResizeColumns(gridRef, containerRef, rawData?.dataset);
  const { gridHeight } = useAgGridCalculatedHeight(containerRef, 65, !!FilterComponent);

  const planetDefinitionTableDefaultExportParams: BaseExportParams = {
    allColumns: true,
    fileName: currentDataset,
    exportedRows: 'all',
    processHeaderCallback: (params: ProcessHeaderForExportParams) =>
      params?.column?.getColDef?.().field ?? '',
  };

  return (
    <DatasetGridContainer
      currentDataset={currentDataset}
      onDatasetSelected={handleDatasetSelected}
      datasetOptions={datasetOptions}
      headerText={headerText}
      description={description}
      actionButtonGroups={actionButtonGroups}
    >
      {FilterComponent}
      <div ref={containerRef}>
        <AgGridBaseWrapper
          gridRef={gridRef}
          rowBuffer={10}
          tooltipShowDelay={1000}
          suppressColumnVirtualisation={true}
          onFirstDataRendered={resizeColumns}
          onGridSizeChanged={resizeColumns}
          onRowDataUpdated={resizeColumns}
          gridHeight={gridHeight}
          enableRangeSelection={true}
          isFullScreen={isFullScreen}
          setFullScreen={setIsFullScreen}
          pagination={true}
          paginationPageSize={AG_OUTPUT_PAGINATION_PAGE_SIZE}
          defaultColDef={defaultColDef}
          rowSelection="multiple"
          animateRows={true}
          rowData={filtersValid && !fetchDataError ? rawData?.dataset : []}
          columnDefs={columnDefs}
          defaultExcelExportParams={planetDefinitionTableDefaultExportParams}
          defaultCsvExportParams={planetDefinitionTableDefaultExportParams}
        />
      </div>
    </DatasetGridContainer>
  );
};

export default PlanOutputDataGrid;
