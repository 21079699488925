import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  TriggerComputeMutationOptions,
  TriggerComputeMutationVariables,
  TriggerComputeMutationHookOptions,
  useTriggerComputeMutation,
} from 'src/api/mutation/useTriggerComputeMutation';
import { useBatchMetadata } from 'src/api/query/useBatchMetadata';
import { useIsPlanPolling } from 'src/pages/commons/compute-helpers/useComputationPollingManager';
import { PlanTypeId, TriggerType } from 'src/utils/planning/planetModel';

type TriggerSpecificComputeMutationVariables = Omit<TriggerComputeMutationVariables, 'trigger'>;

export const useTriggerPlanFullCompute = (
  planType: string | undefined | null,
  options: TriggerComputeMutationHookOptions = {},
) => {
  const { t } = useTranslation();

  let trigger: TriggerType | null = null;
  let inProgressNotificationText = '';
  let errorNotificationText = '';

  switch (planType) {
    case PlanTypeId.CAPEX:
      trigger = TriggerType.CAPEX_CALL_PROCEDURE;
      inProgressNotificationText = t('api_in_progress_trigger_stored_procedure');
      errorNotificationText = t('api_error_trigger_stored_procedure');
      break;
    case PlanTypeId.DEPRECIATION:
      trigger = TriggerType.DEPRECIATION_CALL_PROCEDURE;
      inProgressNotificationText = t('api_in_progress_trigger_stored_procedure');
      errorNotificationText = t('api_error_trigger_stored_procedure');
      break;
    case PlanTypeId.REAL_ESTATE:
      trigger = TriggerType.REAL_ESTATE_CALL_PROCEDURE;
      inProgressNotificationText = t('api_in_progress_trigger_stored_procedure');
      errorNotificationText = t('api_error_trigger_stored_procedure');
      break;
    case PlanTypeId.JANITORIAL:
      trigger = TriggerType.JANITORIAL_CALL_PROCEDURE;
      inProgressNotificationText = t('api_in_progress_trigger_stored_procedure');
      errorNotificationText = t('api_error_trigger_stored_procedure');
      break;
    case PlanTypeId.SECURITY:
      trigger = TriggerType.SECURITY_CALL_PROCEDURE;
      inProgressNotificationText = t('api_in_progress_trigger_stored_procedure');
      errorNotificationText = t('api_error_trigger_stored_procedure');
      break;
    case PlanTypeId.THREE_P_TRANSPORTATION:
      trigger = TriggerType.THREE_P_TRANSPORTATION_CALL_PROCEDURE;
      inProgressNotificationText = t('api_in_progress_trigger_stored_procedure');
      errorNotificationText = t('api_error_trigger_stored_procedure');
      break;
    case PlanTypeId.OTR_WAREHOUSE_TRANSFER:
      trigger = TriggerType.OTR_WHT_CALL_PROCEDURE;
      inProgressNotificationText = t('api_in_progress_trigger_stored_procedure');
      errorNotificationText = t('api_error_trigger_stored_procedure');
      break;
    case PlanTypeId.OTR_DOMESTIC_INBOUND:
      trigger = TriggerType.OTR_DIB_CALL_PROCEDURE;
      inProgressNotificationText = t('api_in_progress_trigger_stored_procedure');
      errorNotificationText = t('api_error_trigger_stored_procedure');
      break;
    case PlanTypeId.OTR_FREIGHT_CAPITALIZATION:
      trigger = TriggerType.OTR_FC_CALL_PROCEDURE;
      inProgressNotificationText = t('api_in_progress_trigger_stored_procedure');
      errorNotificationText = t('api_error_trigger_stored_procedure');
      break;
    case PlanTypeId.TOPS_DOWN_FORECAST:
      trigger = TriggerType.TOPS_DOWN_FORECAST_COMPUTE;
      inProgressNotificationText = t('api_in_progress_trigger_computation');
      errorNotificationText = t('api_error_trigger_computation');
      break;
    case PlanTypeId.UTR_PRODUCTIVITY:
      trigger = TriggerType.UTR_PRODUCTIVITY_CALL_PROCEDURE;
      inProgressNotificationText = t('api_in_progress_trigger_stored_procedure');
      errorNotificationText = t('api_error_trigger_stored_procedure');
      break;
    case PlanTypeId.UTR_COST_HC:
      trigger = TriggerType.UTR_COST_CALL_PROCEDURE;
      inProgressNotificationText = t('api_in_progress_trigger_stored_procedure');
      errorNotificationText = t('api_error_trigger_stored_procedure');
      break;
    default:
      break;
  }

  const { mutate } = useTriggerComputeMutation({
    meta: { inProgressNotificationText, errorNotificationText },
    ...options,
  });

  const [isSendingComputeRequest, setIsSendingComputeRequest] = useState(false);
  const [triggerBatchId, setTriggerBatchId] = useState(undefined as undefined | string);

  const { data: triggerBatchMetadata } = useBatchMetadata({ batchId: triggerBatchId });

  const { isPlanPolling } = useIsPlanPolling(triggerBatchMetadata);

  const triggerFullCompute = useCallback(
    (
      input: TriggerSpecificComputeMutationVariables,
      mutateOptions: TriggerComputeMutationOptions = {},
    ) => {
      if (!trigger) return;

      setIsSendingComputeRequest(true);
      mutate(
        { ...input, trigger },
        {
          ...mutateOptions,
          onError(error, variables, context) {
            setIsSendingComputeRequest(false);
            mutateOptions.onError?.(error, variables, context);
          },
        },
      );
      setTriggerBatchId(input.batchId);
    },
    [trigger, mutate],
  );

  useEffect(() => {
    if (isSendingComputeRequest && isPlanPolling) {
      setIsSendingComputeRequest(false);
    }
  }, [triggerBatchMetadata, isSendingComputeRequest, isPlanPolling]);

  return { triggerFullCompute, isSendingComputeRequest };
};

interface ITriggerPlanLoadDatasetsInput {
  planType: string | undefined | null;
  isLargeDataset?: boolean;
}

export const useTriggerPlanLoadDatasets = (
  input: ITriggerPlanLoadDatasetsInput,
  options: TriggerComputeMutationHookOptions = {},
) => {
  const { t } = useTranslation();

  let trigger: TriggerType | null = null;
  const inProgressNotificationText = t('api_in_progress_trigger_redshift_load');
  const errorNotificationText = t('api_error_trigger_redshift_load');

  switch (input.planType) {
    case PlanTypeId.CAPEX:
      trigger = TriggerType.CAPEX_LOAD_DATASETS;
      break;
    case PlanTypeId.DEPRECIATION:
      trigger = TriggerType.DEPRECIATION_LOAD_DATASETS;
      break;
    case PlanTypeId.REAL_ESTATE:
      trigger = TriggerType.REAL_ESTATE_LOAD_DATASETS;
      break;
    case PlanTypeId.JANITORIAL:
      trigger = TriggerType.JANITORIAL_LOAD_DATASETS;
      break;
    case PlanTypeId.SECURITY:
      trigger = TriggerType.SECURITY_LOAD_DATASETS;
      break;
    case PlanTypeId.THREE_P_TRANSPORTATION:
      trigger = TriggerType.THREE_P_TRANSPORTATION_LOAD_DATASETS;
      break;
    case PlanTypeId.OTR_WAREHOUSE_TRANSFER:
      trigger = TriggerType.OTR_WHT_LOAD_DATASETS;
      break;
    case PlanTypeId.OTR_DOMESTIC_INBOUND:
      trigger = TriggerType.OTR_DIB_LOAD_DATASETS;
      break;
    case PlanTypeId.OTR_FREIGHT_CAPITALIZATION:
      trigger = TriggerType.OTR_FC_LOAD_DATASETS;
      break;
    case PlanTypeId.UTR_PRODUCTIVITY:
      if (input.isLargeDataset) {
        trigger = TriggerType.UTR_LARGE_DATA_UPDATE_AND_LOAD;
      } else {
        trigger = TriggerType.UTR_PRODUCTIVITY_LOAD_DATASETS;
      }
      break;
    case PlanTypeId.UTR_COST_HC:
      if (input.isLargeDataset) {
        trigger = TriggerType.UTR_LARGE_DATA_UPDATE_AND_LOAD;
      } else {
        trigger = TriggerType.UTR_COST_LOAD_DATASETS;
      }
      break;
    default:
      break;
  }

  const { mutate } = useTriggerComputeMutation({
    meta: { inProgressNotificationText, errorNotificationText },
    ...options,
  });

  const triggerLoadDatasets = useCallback(
    (
      input: TriggerSpecificComputeMutationVariables,
      mutateOptions: TriggerComputeMutationOptions = {},
    ) => {
      if (!trigger) return;
      mutate({ ...input, trigger }, mutateOptions);
    },
    [trigger, mutate],
  );

  return { triggerLoadDatasets };
};

export const useTriggerPlanLoadBaseline = (options: TriggerComputeMutationHookOptions = {}) => {
  const { t } = useTranslation();

  const trigger = TriggerType.BASELINE_LOAD;
  const inProgressNotificationText = t('api_in_progress_trigger_baseline_load');
  const errorNotificationText = t('api_error_trigger_baseline_load');

  const { mutate } = useTriggerComputeMutation({
    meta: { inProgressNotificationText, errorNotificationText },
    ...options,
  });

  const triggerLoadBaseline = useCallback(
    (
      input: TriggerSpecificComputeMutationVariables,
      mutateOptions: TriggerComputeMutationOptions = {},
    ) => {
      mutate({ ...input, trigger }, mutateOptions);
    },
    [mutate, trigger],
  );

  return { triggerLoadBaseline };
};

export const useTriggerTopsDownForecastCreatePlan = (
  options: TriggerComputeMutationHookOptions = {},
) => {
  const { t } = useTranslation();

  const trigger = TriggerType.TOPS_DOWN_FORECAST_CREATE_PLAN;
  const inProgressNotificationText = t('api_in_progress_trigger_create_plan');
  const errorNotificationText = t('api_error_trigger_create_plan');

  const { mutate } = useTriggerComputeMutation({
    meta: { inProgressNotificationText, errorNotificationText },
    ...options,
  });

  const triggerTopsDownForecastCreatePlan = useCallback(
    (
      input: TriggerSpecificComputeMutationVariables,
      mutateOptions: TriggerComputeMutationOptions = {},
    ) => {
      mutate({ ...input, trigger }, mutateOptions);
    },
    [mutate, trigger],
  );

  return { triggerTopsDownForecastCreatePlan };
};

export const useTriggerFixedCostConsolidationCreatePlan = (
  options: TriggerComputeMutationHookOptions = {},
) => {
  const { t } = useTranslation();

  const trigger = TriggerType.FIXED_COST_CONSOLIDATION_CREATE_PLAN;
  const inProgressNotificationText = t('api_in_progress_trigger_create_plan');
  const errorNotificationText = t('api_error_trigger_create_plan');

  const { mutate } = useTriggerComputeMutation({
    meta: { inProgressNotificationText, errorNotificationText },
    ...options,
  });

  const triggerFixedCostConsolidationCreatePlan = useCallback(
    (
      input: TriggerSpecificComputeMutationVariables,
      mutateOptions: TriggerComputeMutationOptions = {},
    ) => {
      mutate({ ...input, trigger }, mutateOptions);
    },
    [mutate, trigger],
  );

  return { triggerFixedCostConsolidationCreatePlan };
};

export const useTriggerPlanImportBaseline = (options: TriggerComputeMutationHookOptions = {}) => {
  const { t } = useTranslation();

  const trigger = TriggerType.TOPS_DOWN_FORECAST_BASELINE_IMPORT;
  const inProgressNotificationText = t('api_in_progress_trigger_baseline_import');
  const errorNotificationText = t('api_error_trigger_baseline_import');

  const { mutate } = useTriggerComputeMutation({
    meta: { inProgressNotificationText, errorNotificationText },
    ...options,
  });

  const triggerImportBaseline = useCallback(
    (
      input: TriggerSpecificComputeMutationVariables,
      mutateOptions: TriggerComputeMutationOptions = {},
    ) => {
      mutate({ ...input, trigger }, mutateOptions);
    },
    [mutate, trigger],
  );

  return { triggerImportBaseline };
};

export const useTriggerComputeFromMM = (options: TriggerComputeMutationHookOptions = {}) => {
  const { t } = useTranslation();

  const trigger = TriggerType.TOPS_DOWN_FORECAST_COMPUTE_FROM_MM;
  const inProgressNotificationText = t('api_in_progress_trigger_computation');
  const errorNotificationText = t('api_error_trigger_computation');

  const { mutate } = useTriggerComputeMutation({
    meta: { inProgressNotificationText, errorNotificationText },
    ...options,
  });

  const triggerComputeFromMM = useCallback(
    (
      input: TriggerSpecificComputeMutationVariables,
      mutateOptions: TriggerComputeMutationOptions = {},
    ) => {
      mutate({ ...input, trigger }, mutateOptions);
    },
    [mutate, trigger],
  );

  return { triggerComputeFromMM };
};

export const useTriggerComputeFromCPU = (options: TriggerComputeMutationHookOptions = {}) => {
  const { t } = useTranslation();

  const trigger = TriggerType.TOPS_DOWN_FORECAST_COMPUTE_FROM_CPU;
  const inProgressNotificationText = t('api_in_progress_trigger_computation');
  const errorNotificationText = t('api_error_trigger_computation');

  const { mutate } = useTriggerComputeMutation({
    meta: { inProgressNotificationText, errorNotificationText },
    ...options,
  });

  const triggerComputeFromCPU = useCallback(
    (
      input: TriggerSpecificComputeMutationVariables,
      mutateOptions: TriggerComputeMutationOptions = {},
    ) => {
      mutate({ ...input, trigger }, mutateOptions);
    },
    [mutate, trigger],
  );

  return { triggerComputeFromCPU };
};

export const useTriggerLunaExport = (options: TriggerComputeMutationHookOptions = {}) => {
  const { t } = useTranslation();

  const trigger = TriggerType.TOPS_DOWN_FORECAST_LUNA_EXPORT;
  const inProgressNotificationText = t('api_in_progress_trigger_export');
  const errorNotificationText = t('api_error_trigger_export');

  const { mutate } = useTriggerComputeMutation({
    meta: { inProgressNotificationText, errorNotificationText },
    ...options,
  });

  const triggerLunaExport = useCallback(
    (
      input: TriggerSpecificComputeMutationVariables,
      mutateOptions: TriggerComputeMutationOptions = {},
    ) => {
      mutate({ ...input, trigger }, mutateOptions);
    },
    [mutate, trigger],
  );

  return { triggerLunaExport };
};

export const useTriggerTopsDownVolumeDatasetImport = (
  options: TriggerComputeMutationHookOptions = {},
) => {
  const { t } = useTranslation();

  const trigger = TriggerType.TOPS_DOWN_FORECAST_VOLUME_DATASET_IMPORT;
  const inProgressNotificationText = t('api_in_progress_trigger_import');
  const errorNotificationText = t('api_error_trigger_import');

  const { mutate } = useTriggerComputeMutation({
    meta: { inProgressNotificationText, errorNotificationText },
    ...options,
  });

  const triggerTopsDownVolumeDatasetImport = useCallback(
    (
      input: TriggerSpecificComputeMutationVariables,
      mutateOptions: TriggerComputeMutationOptions = {},
    ) => {
      mutate({ ...input, trigger }, mutateOptions);
    },
    [mutate, trigger],
  );

  return { triggerTopsDownVolumeDatasetImport };
};

export const useTriggerTopsDownBaselineCpuDatasetImport = (
  options: TriggerComputeMutationHookOptions = {},
) => {
  const { t } = useTranslation();

  const trigger = TriggerType.TOPS_DOWN_FORECAST_BASELINE_CPU_DATASET_IMPORT;
  const inProgressNotificationText = t('api_in_progress_trigger_import');
  const errorNotificationText = t('api_error_trigger_import');

  const { mutate } = useTriggerComputeMutation({
    meta: { inProgressNotificationText, errorNotificationText },
    ...options,
  });

  const triggerTopsDownBaselineCpuDatasetImport = useCallback(
    (
      input: TriggerSpecificComputeMutationVariables,
      mutateOptions: TriggerComputeMutationOptions = {},
    ) => {
      mutate({ ...input, trigger }, mutateOptions);
    },
    [mutate, trigger],
  );

  return { triggerTopsDownBaselineCpuDatasetImport };
};

export const useTriggerExtractOutput = (
  planType: string | undefined | null,
  options: TriggerComputeMutationHookOptions = {},
) => {
  const { t } = useTranslation();

  let trigger: TriggerType | null = null;
  const inProgressNotificationText = t('api_in_progress_trigger_redshift_extract');
  const errorNotificationText = t('api_error_trigger_redshift_extract');

  switch (planType) {
    case PlanTypeId.CAPEX:
      trigger = TriggerType.CAPEX_EXTRACT_OUTPUT;
      break;
    case PlanTypeId.DEPRECIATION:
      trigger = TriggerType.DEPRECIATION_EXTRACT_OUTPUT;
      break;
    case PlanTypeId.REAL_ESTATE:
      trigger = TriggerType.REAL_ESTATE_EXTRACT_OUTPUT;
      break;
    case PlanTypeId.THREE_P_TRANSPORTATION:
      trigger = TriggerType.THREE_P_TRANSPORTATION_EXTRACT_OUTPUT;
      break;
    case PlanTypeId.OTR_WAREHOUSE_TRANSFER:
      trigger = TriggerType.OTR_WHT_EXTRACT_OUTPUT;
      break;
    case PlanTypeId.OTR_DOMESTIC_INBOUND:
      trigger = TriggerType.OTR_DIB_EXTRACT_OUTPUT;
      break;
    case PlanTypeId.OTR_FREIGHT_CAPITALIZATION:
      trigger = TriggerType.OTR_FC_EXTRACT_OUTPUT;
      break;
    case PlanTypeId.UTR_PRODUCTIVITY:
      trigger = TriggerType.UTR_PRODUCTIVITY_EXTRACT_OUTPUT;
      break;
    case PlanTypeId.UTR_COST_HC:
      trigger = TriggerType.UTR_COST_EXTRACT_OUTPUT;
      break;
    default:
      break;
  }

  const { mutate } = useTriggerComputeMutation({
    meta: { inProgressNotificationText, errorNotificationText },
    ...options,
  });

  const triggerExtractOutput = useCallback(
    (
      input: TriggerSpecificComputeMutationVariables,
      mutateOptions: TriggerComputeMutationOptions = {},
    ) => {
      if (!trigger) return;
      mutate({ ...input, trigger }, mutateOptions);
    },
    [trigger, mutate],
  );

  return { triggerExtractOutput, isExtractOutputSupported: trigger !== null };
};
